import { z } from 'zod';

export const boolValue = z
  .string()
  // only allow "true" or "false"
  .refine((s) => s === 'true' || s === 'false')
  // transform to boolean
  .transform((s) => s === 'true');

export const numberValue = z
  .string()
  // transform to number
  .transform((s) => parseInt(s, 10))
  // make sure transform worked
  .pipe(z.number());
